@import "../../index.scss";

footer {
  background: $stubby-purple-30;
  border-top: 1px solid $stubby-purple-10;
  color: $stubby-white;
  text-align: center;

  .page-footer {
    margin: 0 auto;
    padding: 24px;
  }

  img {
    display: block;
    margin: 0 auto;
    width: 220px;
  }
}

@include breakpoint() {
  footer {
    .page-footer {
      align-items: center;
      display: flex;
      justify-content: center;
      text-align: left;
    }

    img {
      margin: 0 0 0 16px;
    }
  }
}
