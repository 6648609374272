@mixin button($btn-color-1: $stubby-white, $btn-color-2: $stubby-purple-10) {
  background: $btn-color-1;
  box-shadow: 0 0 1px 1px $btn-color-1;
  border: 2px solid $btn-color-2;
  border-radius: 2px;
  color: $btn-color-2;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin: calcRem(24px) 0;
  padding: calcRem(8px) calcRem(12px);
  position: relative;

  span {
    position: relative;
    z-index: 10;
  }

  @include font-size(14px);

  &:after {
    background: $btn-color-2;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.3s ease-in-out;
    width: 0;
  }

  &:hover {
    background: lighten($btn-color-2, 10%);
    box-shadow: 0 0 1px 1px $btn-color-1;
    border: 2px solid $btn-color-2;
    color: $btn-color-1;

    &:after {
      height: 100%;
      width: 100%;
    }
  }
}

.btn {
  @include button();
}

.indeed-btn {
  @include button($stubby-white, $indeed-blue);

  &:visited {
    color: $indeed-blue;
  }
}

.linkedin-btn {
  @include button($stubby-white, $linkedin-blue);

  &:visited {
    color: $linkedin-blue;
  }
}

.btn-container {
  display: flex;
  justify-content: space-around;
}

@include breakpoint() {
  .article {
    .btn {
      padding: calcRem(12px) calcRem(24px);

      @include font-size(16px);
    }

    .btn-container {
      justify-content: flex-start;
    }

    .linkedin-btn {
      margin-right: calcRem(16px);
    }
  }
}
