@import url("https://fonts.googleapis.com/css2?family=Fauna+One&family=Raleway:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700&display=swap");
@import "styles/reset";
@import "styles/variables";
@import "styles/mixins";
@import "styles/buttons";

body {
  background: $stubby-purple-20;
  color: $stubby-white;
  font-family: "Raleway", Helvetica, Arial, sans-serif;

  @include font-size(16px);
}

.container {
  margin: 24px auto;
  max-width: 700px;
  padding: 0 24px;

  .section-title {
    margin: calcRem(48px) 0 16px;
    text-transform: uppercase;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);

    @include font-size(18px);
  }
}

main {
  color: $stubby-white;
  width: 100vw;
}

h1,
h2,
h3,
h4 {
  font-family: "Fauna One", Helvetica, Arial, sans-serif;
}

h1 {
  @include font-size(44px);
}

h2 {
  @include font-size(32px);
}

h3 {
  @include font-size(22px);
}

h4 {
  @include font-size(18px);
}

h5 {
  @include font-size(16px);
}

h6 {
  font-weight: 700;

  @include font-size(14px);
}

a,
a:visited {
  color: $stubby-blue;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: $stubby-white-10;
  }
}

img {
  max-width: 100%;
}

.icon {
  @include icon(18px);
}

@include breakpoint() {
  h1 {
    @include font-size(48px);
  }

  h2 {
    @include font-size(36px);
  }

  h3 {
    @include font-size(24px);
  }
}
