@import "../../index.scss";
@import "animation";

.header-main {
  align-items: center;
  background-color: $stubby-white-10;
  background-position: -(calcRem(20px)) bottom;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 20px solid $stubby-purple-30;
  box-shadow: 0 1px 3px $stubby-purple-10;
  color: $stubby-white;
  display: flex;
  flex-direction: column;
  margin-bottom: calcRem(48px);
  padding: calcRem(24px) 0 calcRem(160px);
  text-align: center;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.35);

  h1 {
    @include font-size(36px);
  }

  img {
    max-width: calcRem(75px);
  }
}

@include breakpoint() {
  .header-main {
    background-position: center bottom;
    background-size: cover;
    height: calcRem(475px);
    padding: calcRem(48px) 0;

    h1 {
      @include font-size(48px);
    }
  }
}
