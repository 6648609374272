@import "../../index.scss";

.skills {
  ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: calcRem(16px);
  }

  li {
    margin-bottom: calcRem(48px);
    text-align: center;
  }

  img {
    display: block;
    margin: 0 auto;
    height: calcRem(90px);
  }

  h4 {
    margin-bottom: 16px;
  }
}

@include breakpoint() {
  .skills {
    li {
      img {
        height: calcRem(110px);
      }
    }
  }
}
