@import "./variables";

@function calcRem($size) {
  $rem-size: $size / 16px;
  @return #{$rem-size}rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calcRem($size);
  line-height: 1.4;
}

@mixin icon($size, $cursor: pointer) {
  cursor: $cursor;
  height: $size;
  width: $size;
}

@mixin breakpoint($width: $md) {
  @media screen and (min-width: $width) {
    @content;
  }
}
