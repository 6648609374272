@import "../../index.scss";

.modal-outer {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;

  .overlay {
    background: rgba(0, 0, 0, 0.75);
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1010;
  }
}

.modal-inner {
  background: $stubby-white;
  border-radius: 2px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.9);
  color: $stubby-black-90;
  font-family: "Fauna One", Helvetica, Arial, sans-serif;
  max-height: 90vh;
  margin: 5% auto 0;
  max-width: calcRem(900px);
  overflow: hidden;
  padding: calcRem(4px);
  position: relative;
  z-index: 1020;

  header {
    display: flex;
    justify-content: space-between;
  }

  h3 {
    margin-bottom: calcRem(8px);

    @include font-size(18px);
  }

  img {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.55);
    display: block;
    margin: 0 auto;
    max-height: 75vh;
  }

  .icon-close {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

@include breakpoint() {
  .modal-inner {
    margin: calcRem(24px) auto 0;
    padding: calcRem(16px);

    h3 {
      margin-bottom: calcRem(16px);

      @include font-size(22px);
    }
  }
}
