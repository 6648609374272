.header-main {
  animation: fade-in 1s;
  opacity: 1;
}

.hero-content {
  animation: hero-animations 1.1s;
}

@keyframes fade-in {
  0% {
    opacity: 0.1;
  }
}

@keyframes hero-animations {
  0% {
    margin-top: -(calcRem(150px));
    margin-bottom: calcRem(174px);
  }
}
