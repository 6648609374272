$stubby-white: #fff;
$stubby-white-10: #f9f9ea;
$stubby-black: #000;
$stubby-black-90: #2d2d2d;
$stubby-black-80: #4c4c4c;
$stubby-blue: #7096aa;
$stubby-purple-10: #495075;
$stubby-purple-20: #352a45;
$stubby-purple-30: #2b2633;
$stubby-error: #d8b1b2;

$linkedin-blue: #0072b1;
$indeed-blue: #085ff7;

// Media breakpoints
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
