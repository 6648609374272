@import "../../index.scss";

.portfolio {
  .screenshot {
    margin-bottom: calcRem(32px);
    margin-top: calcRem(32px);

    h3 {
      margin-bottom: calcRem(8px);
    }

    p {
      margin-bottom: calcRem(16px);
    }
  }
}

.thumbnail-content {
  .thumbnail-wrapper {
    position: relative;
    z-index: 10;

    &:after {
      background: transparent;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: none;
      width: 100%;
      z-index: 100;
    }

    &:hover {
      margin: 0;

      img {
        border-color: $stubby-blue;
        transition: none;
      }
    }
  }

  img {
    border: 3px solid $stubby-white-10;
    cursor: pointer;
    width: 100%;
  }
}

@include breakpoint() {
  .portfolio {
    ul {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-gap: calcRem(16px);
    }

    .screenshot {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .thumbnail-content {
    .thumbnail-wrapper {
      transition: 0.3s ease-in-out;

      &:after {
        display: none;
      }

      &:hover {
        margin: calcRem(-5px) calcRem(-5px) calcRem(-1px);

        img {
          transition: 0.6s ease-in-out;
        }
      }
    }
  }
}
